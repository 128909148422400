var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "mt-4 ml-8 text-2xl font-semibold" }, [
      _vm._v("Applicants"),
    ]),
    _c(
      "div",
      { staticClass: "w-full p-4 bg-white" },
      [
        _c(
          "async-table-component",
          _vm._g(
            _vm._b(
              {
                attrs: {
                  isSortable: true,
                  isColumnEditable: true,
                  isFilterable: true,
                  localStorageKey: "applicant-collection-table",
                  emptyTableMessage: "0 Applicants",
                },
              },
              "async-table-component",
              _vm.applicantSearchService.tableProps,
              false
            ),
            _vm.applicantSearchService.tableEventListeners
          ),
          [_vm._v(" >")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }