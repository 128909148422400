import { SearchCandidateDto } from '@/models/Dtos/searchCandidateDto';
import {
  booleanFilterComponentDataBuilder,
  stringFilterComponentDataBuilder
} from '@/models/Table/Table';

/**
 * Filters go into the filter dropdown and usually have inputs to define the filter value
 */
export const candidateFirstNameFilter = stringFilterComponentDataBuilder(
  'First Name',
  (dto: SearchCandidateDto, value: string) => (dto.firstName = value)
);

export const candidateCityFilter = stringFilterComponentDataBuilder(
  'City',
  (dto: SearchCandidateDto, value: string) => (dto.city = value)
);

export const candidateZipCodeFilter = stringFilterComponentDataBuilder(
  'Zip Code',
  (dto: SearchCandidateDto, value: string) => (dto.zipCode = value)
);

export const candidateLastNameFilter = stringFilterComponentDataBuilder(
  'Last Name',
  (dto: SearchCandidateDto, value: string) => (dto.lastName = value)
);

export const candidateEmailFilter = stringFilterComponentDataBuilder(
  'Email',
  (dto: SearchCandidateDto, value: string) => (dto.email = value)
);

export const candidateIsArchivedFilter = booleanFilterComponentDataBuilder(
  'Is Archived',
  (dto: SearchCandidateDto, value: boolean) => (dto.isArchived = value)
);
