import { SearchCandidateDto } from '@/models/Dtos/searchCandidateDto';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import ApplicantSelectRecruiterDropDown from '../../Elements/ApplicantSelectRecruiterDropDown.vue';
import LinkElementComponent from '../../Elements/LinkElementComponent.vue';
import ShowSocialSecurityComponent from '../../Elements/ShowSocialSecurityComponent.vue';
import { TableConfigBuilder } from '../../models/TableConfigBuilder';
import TableSearchService from '../../models/TableSearch.service';
import { RecruitingApplicantTableData } from './RecruitingApplicantTableData';

export const recruitingApplicantTableConfigBuilder: TableConfigBuilder<
  RecruitingApplicantTableData,
  BaseCandidate,
  never,
  SearchCandidateDto
> = (
  tableSearchService: TableSearchService<
    RecruitingApplicantTableData,
    BaseCandidate,
    never,
    SearchCandidateDto
  >
): ITableConfiguration<RecruitingApplicantTableData, BaseCandidate, never> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'Last Name',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowCandidate: RecruitingApplicantTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowCandidate.lastName,
              linkTo: {
                name: Routes.APPLICANT_DETAILS,
                params: { id: rowCandidate.id }
              }
            };
          }
        },
        sortKey: 'identificationInformationLastname',
        defaultPinned: true
      },
      {
        columnTitle: 'First Name',
        render: 'firstName',
        sortKey: 'identificationInformationFirstname',
        defaultPinned: true
      },
      {
        columnTitle: 'Email',
        render: 'email',
        sortKey: 'contactInformationEmail',
        defaultPinned: true
      },
      {
        columnTitle: 'Home Phone',
        render: 'homePhone',
        sortKey: 'contactInformationHomephone'
      },
      {
        columnTitle: 'Social Security Number',
        render: {
          componentRef: ShowSocialSecurityComponent,
          componentProps: (
            rowCandidate: RecruitingApplicantTableData
          ): Record<string, unknown> => {
            return {
              ssnString: rowCandidate.socialSecurityNumber
            };
          }
        }
      },
      {
        columnTitle: 'Zip Code',
        render: 'zipCode',
        sortKey: 'contactInformationZipcode'
      },
      {
        columnTitle: 'Middle Name',
        render: 'middleName',
        sortKey: 'identificationInformationMiddlename'
      },
      {
        columnTitle: 'Address 1',
        render: 'address1',
        sortKey: 'contactInformationAddress1'
      },
      {
        columnTitle: 'Address 2',
        render: 'address2',
        sortKey: 'contactInformationAddress2'
      },
      {
        columnTitle: 'City',
        render: 'city',
        sortKey: 'contactInformationCity'
      },
      {
        columnTitle: 'State',
        render: 'state',
        sortKey: 'contactInformationState'
      },
      {
        columnTitle: 'Work Phone',
        render: 'workPhone',
        sortKey: 'contactInformationWorkphone'
      },
      {
        columnTitle: 'Recruiter',
        render: {
          componentRef: ApplicantSelectRecruiterDropDown,
          componentProps: (
            rowCandidate: RecruitingApplicantTableData
          ): Record<string, unknown> => {
            return {
              applicant: rowCandidate,
              updateDelegate:
                tableSearchService.queryData.bind(tableSearchService)
            };
          }
        }
      }
    ]
  };
};
