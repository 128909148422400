
import AsyncTableComponent from '@/components/Table/AsyncTableComponent.vue';
import { RecruitingApplicantDataProvider } from '@/components/Table/Candidate/RecruitingApplicant/RecruitingApplicantDataProvider';
import { recruitingApplicantTableConfigBuilder } from '@/components/Table/Candidate/RecruitingApplicant/RecruitingApplicantTableConfigBuilder';
import { RecruitingApplicantTableData } from '@/components/Table/Candidate/RecruitingApplicant/RecruitingApplicantTableData';
import {
  candidateCityFilter,
  candidateEmailFilter,
  candidateFirstNameFilter,
  candidateIsArchivedFilter,
  candidateLastNameFilter,
  candidateZipCodeFilter
} from '@/components/Table/Candidate/CandidateFilters';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import { SearchCandidateDto } from '@/models/Dtos/searchCandidateDto';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RecruitmentStatus } from '@/models/Entities/Personas/Constants';

@Component<ApplicantCollectionPage>({
  components: {
    AsyncTableComponent
  }
})
export default class ApplicantCollectionPage extends Vue {
  @Prop({ default: () => [] })
  readonly applicants!: Applicant[];

  applicantSearchService = new TableSearchService<
    RecruitingApplicantTableData,
    BaseCandidate,
    never,
    SearchCandidateDto
  >(
    this.$store,
    new RecruitingApplicantDataProvider(this.$store),
    {
      recruitmentStatus: RecruitmentStatus.APPLICANT
    },
    { defaultSortProperty: 'identificationInformationLastname' },
    recruitingApplicantTableConfigBuilder,
    {
      filters: [
        candidateFirstNameFilter,
        candidateLastNameFilter,
        candidateCityFilter,
        candidateZipCodeFilter,
        candidateEmailFilter,
        candidateIsArchivedFilter
      ],
      quickFilters: []
    }
  );

  mounted(): void {
    this.applicantSearchService.queryData();
  }

  selectedApplicants: Applicant[] = [];

  selectRowsHandler(applicants: Applicant[]): void {
    this.selectedApplicants = applicants;
  }
}
