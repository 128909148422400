import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { SearchCandidateDto } from '@/models/Dtos/searchCandidateDto';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { TableDataProvider } from '../../models/TableDataProvider';
import { RecruitingApplicantTableData } from './RecruitingApplicantTableData';

export class RecruitingApplicantDataProvider extends TableDataProvider<
  RecruitingApplicantTableData,
  BaseCandidate,
  SearchCandidateDto
> {
  protected transformer = (t: BaseCandidate): RecruitingApplicantTableData =>
    new RecruitingApplicantTableData(t);

  protected queryHandler = async (
    searchDto: SearchCandidateDto
  ): Promise<SearchResponseDto<BaseCandidate>> => {
    const results: SearchResponseDto<BaseCandidate> = await this.store.dispatch(
      'studentModule/fetchWithSelectParameters',
      searchDto
    );
    return results;
  };
}
