import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { ITableData } from '../../models/TableData';

export class RecruitingApplicantTableData implements ITableData {
  entityId: number;
  id: number;
  managerId: number | null;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  email: string | null;
  isArchived: boolean;
  socialSecurityNumber: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
  workPhone: string | null;
  homePhone: string;

  constructor(candidate: BaseCandidate) {
    this.entityId = candidate.id;
    this.firstName = candidate.identificationInformationFirstname;
    this.middleName = candidate.identificationInformationMiddlename;
    this.lastName = candidate.identificationInformationLastname;
    this.email = candidate.contactInformationEmail;
    this.isArchived = candidate.isArchived;
    this.id = candidate.id;
    this.managerId = candidate.managerId;
    this.socialSecurityNumber =
      candidate.identificationInformationSocialsecuritynumber;
    this.address1 = candidate.contactInformationAddress1;
    this.address2 = candidate.contactInformationAddress2;
    this.city = candidate.contactInformationCity;
    this.state = candidate.contactInformationState;
    this.zipCode = candidate.contactInformationZipcode;
    this.workPhone = formatPhoneNumber(
      '' + candidate.contactInformationWorkphone
    );
    this.homePhone =
      '' + formatPhoneNumber('' + candidate.contactInformationHomephone);
  }
}

function formatPhoneNumber(str: string) {
  const cleaned = ('' + str).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return '';
}
